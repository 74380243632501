import React, { MouseEvent, MouseEventHandler, ReactNode } from 'react';
import IconTab from '@bridebook/ui/src/components/bbcommon/tabs/icon-tab';
import { NavigationDestination } from 'lib/analyticsTypes';
import { isActiveTab } from 'lib/bbcommon/selectors';
import { hapticFeedbackLight } from 'lib/mobile-app/utils/haptic-feedback-light';
import { useSelector } from 'lib/utils';
import styles from './bottom-tab-mobile.style';

interface IBottomTabMobileProps {
  bottomTab: BottomTab;
}

export interface BottomTab {
  id: string;
  title: string;
  url?: string;
  destination: NavigationDestination;
  icon: JSX.Element;
  iconActive: JSX.Element;
  onClick(e: MouseEvent<ReactNode>, bottomTab: BottomTab): void;
}

const BottomTabMobile: React.FC<IBottomTabMobileProps> = ({ bottomTab }) => {
  const isActive = useSelector((state) => isActiveTab(state, bottomTab));
  const handleClick: MouseEventHandler<ReactNode> = (e) => {
    e.preventDefault();
    bottomTab.onClick(e, bottomTab);
    hapticFeedbackLight();
  };

  return (
    <IconTab
      style={styles.iconTabWrap}
      onClick={handleClick}
      icon={bottomTab.icon}
      iconActive={bottomTab.iconActive}
      isActive={isActive}>
      {bottomTab.title}
    </IconTab>
  );
};

export default React.memo(BottomTabMobile);
