import { FelaCSS, colors, spacing } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  resetWrap: FelaCSS;
  sectionWrap: FelaCSS;
  tabsWrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    padding: `${spacing[4]} ${spacing[4]} 0 ${spacing[4]}`,
    overflowX: 'hidden',
    borderBottom: `1px ${colors.space15} solid`,
    WebkitOverflowScrolling: 'touch',
    flex: 1,
    maxWidthTabletSm: {
      paddingTop: 4,
    },
    minWidthTablet: {
      maxHeight: '60vh',
      overflowY: 'auto',
      overscrollBehavior: 'none',
      boxShadow: `0 0 4px ${colors.space15}`,
    },
  },

  resetWrap: {
    alignItems: 'flex-end',
  },

  sectionWrap: {
    flexShrink: 0,
  },

  tabsWrapper: {
    padding: 4,
    backgroundColor: colors.white,
    borderBottom: `1px ${colors.space15} solid`,
  },
});

export default styles;
