import { useTranslation } from 'next-i18next';
import React, { useCallback } from 'react';
import { Box } from '@bridebook/ui';
import { IconAllFilters } from '@bridebook/ui/src/icons';
import FilterPill from 'components/search/filters/quick-filters/filter-pill';
import { getScreenType } from 'lib/app/selectors';
import { toggleFilters, toggleFiltersAnalytics } from 'lib/search/actions';
import { getFiltersCount } from 'lib/search/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import styles from './all-filters-button.style';

export const AllFiltersButton = () => {
  const { t } = useTranslation('search');
  const { isMobile } = useSelector(getScreenType);
  const filtersCount = useSelector(getFiltersCount);
  const dispatch = useDispatch();
  const toggleFiltersBar = useCallback(() => {
    dispatch(toggleFilters());
    dispatch(toggleFiltersAnalytics('searchBar'));
  }, [dispatch]);

  const pillHeight = isMobile ? 40 : 35;

  return (
    <Box style={styles.wrapper(filtersCount)}>
      <FilterPill onClick={toggleFiltersBar} filterSelected={filtersCount > 0} height={pillHeight}>
        {t('mobileControls.button.filters')} <IconAllFilters style={styles.icon} color="amber" />
      </FilterPill>
    </Box>
  );
};
