import { FelaCSSWithCustomSelectors } from '../../../components/fela/flowtypes';

interface StyleTypes {
  snackbarWrapper: (show: boolean, snackbarHeight: number) => FelaCSSWithCustomSelectors;
}

const styles = (): StyleTypes => ({
  snackbarWrapper: (show, offset): FelaCSSWithCustomSelectors => ({
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: 9999999,
    position: 'fixed',
    left: 0,

    top: `calc(env(safe-area-inset-top) + ${offset}px)`,
    width: '100%',
    pointerEvents: show ? 'all' : 'none',
    overflow: 'hidden',

    maxWidthTablet: {
      paddingHorizontal: 4,
    },
  }),
});

export default styles;
