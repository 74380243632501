import { useRouter } from 'next/router';
import React, { memo, useEffect, useMemo } from 'react';
import { isNil } from 'remeda';
import { countriesWithMultiEnquiryModal } from '@bridebook/toolbox/src/i18n/features';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { fetchEnquiryConfirmationSupplier } from 'lib/enquiries/actions';
import { getEnquiryConfirmationSupplier } from 'lib/enquiries/selectors';
import { useDispatch, useSelector } from 'lib/utils';
import { lazyComponent } from 'lib/utils/lazy/lazy-component';
import { getWeddingProfileId } from 'lib/weddings/selectors';

const MultiEnquiryModal = lazyComponent(() => import('components/enquiries/multi-enquiry-modal'));

const MultiEnquiryModalLazy: React.FC = () => {
  const dispatch = useDispatch();
  const { country } = useMarket();
  const weddingId = useSelector(getWeddingProfileId);
  const enquiryConfirmationSupplier = useSelector(getEnquiryConfirmationSupplier);
  const { query } = useRouter();
  const { enquirySupplierId } = query;
  const supplierId = Array.isArray(enquirySupplierId) ? enquirySupplierId[0] : enquirySupplierId;

  const isMultiEnquiryAvailable = useMemo(
    () => countriesWithMultiEnquiryModal.includes(country),
    [country],
  );

  const showMultiEnquiryModal =
    query?.multiEnquiry === 'true' &&
    enquiryConfirmationSupplier?.publicId === enquirySupplierId &&
    !isNil(enquiryConfirmationSupplier) &&
    isMultiEnquiryAvailable;

  useEffect(() => {
    if (supplierId && weddingId && isMultiEnquiryAvailable) {
      dispatch(fetchEnquiryConfirmationSupplier(supplierId));
    }
  }, [dispatch, supplierId, weddingId, isMultiEnquiryAvailable]);

  return showMultiEnquiryModal ? <MultiEnquiryModal /> : null;
};

export default memo(MultiEnquiryModalLazy);
