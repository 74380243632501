import { FelaCSSWithCustomSelectors, colors } from '@bridebook/ui';

interface IStyleTypes {
  wrapper: FelaCSSWithCustomSelectors;
}

const styles = (): IStyleTypes => ({
  wrapper: {
    height: '100vh',
    backgroundColor: colors.white,
    overflow: 'hidden',

    minWidthTablet: {
      height: '80vh',
    },

    supportsSafeAreaInset: {
      paddingTop: 'env(safe-area-inset-top)',
      paddingBottom: 'env(safe-area-inset-bottom)',
    },
  },
});

export default styles;
