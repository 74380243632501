import { ChangeEvent, FunctionComponent } from 'react';
import {
  default as BBUICheckbox,
  IProps as IBBUICheckboxProps,
} from '@bridebook/ui/src/components/bbcommon/inputs/checkbox/checkbox';
import { hapticFeedbackLight } from 'lib/mobile-app/utils/haptic-feedback-light';
import { WithFeedback, withFeedback } from '../with-feedback-hoc';

/**
 * This is a wrapper component for web to be able
 * to handle ALL haptic feedback within the app.
 *
 * We could also have moved this to Redux state handling
 * but the issue with that is, that due to latencies
 * involved with Redux, the haptic feedback sometimes
 * doesn't feel "native" enough.
 */
const Checkbox: FunctionComponent<WithFeedback<IBBUICheckboxProps>> = ({
  withFeedback,
  ...props
}) => {
  const _onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (withFeedback) {
      hapticFeedbackLight();
    }

    props.onChange?.(event);
  };

  return <BBUICheckbox {...props} onChange={_onChange} />;
};

export default withFeedback(Checkbox);
