import { FelaCSS, colors } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
}

const styles = (): IStyles => ({
  wrapper: {
    alignItems: 'flex-start',
    marginTop: 0,
    marginBottom: 0,
    fontDefaultSemiBold: 24,
    lineHeight: '1.2em',
    color: colors.space,

    maxWidthMobile: {
      fontDefaultSemiBold: 20,
    },

    maxWidthTabletSm: {
      minWidthTablet: {
        fontDefaultSemiBold: 24,
      },
    },

    maxWidthTablet: {
      minWidthTabletLg: {
        fontDefaultSemiBold: 24,
      },
    },

    minWidthDesktop: {
      fontDefaultSemiBold: 24,
    },
  },
});

export default styles;
