import { FelaCSS, FelaCSSWithCustomSelectors, colors, spacing } from '@bridebook/ui';

interface IStyles {
  wrapper: FelaCSS;
  bodyWrapper: FelaCSS;
  title: FelaCSS;
  imageWrapper: FelaCSS;
  buttonWrapper: FelaCSS;
  modal: FelaCSSWithCustomSelectors;
  content: FelaCSS;
  innerContent: FelaCSS;
  description: FelaCSSWithCustomSelectors;
}

const styles = (): IStyles => ({
  wrapper: {
    height: '100vh',
  },

  bodyWrapper: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    alignItems: 'center',
    textAlign: 'center',
    overflow: 'auto',
    height: '100%',
    supportsSafeAreaInset: {
      marginTop: 'env(safe-area-inset-top)',
      marginBottom: 'env(safe-area-inset-bottom)',
    },
  },
  innerContent: {
    flex: 1,
    alignItems: 'center',
    overflow: 'auto',
    gap: spacing[8],
  },
  title: {
    fontDefaultBold: 26,

    /** Small devices like iPhones SE */
    maxWidthMobile: {
      fontDefaultBold: 22,
    },
  },
  imageWrapper: {
    flex: 1,
    position: 'relative',
    width: '100%',
    minHeight: '280px',
    maxHeight: '560px',
  },
  buttonWrapper: {
    paddingTop: 4,
    paddingBottom: 2,
    width: '100%',
  },
  modal: {
    background: colors.white,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    alignItems: 'center',
  },
  description: {
    color: colors.space60,
    paddingBottom: 2,
    fontDefault: 16,
    //temp SE detection
    '@media only screen and (min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2)':
      {
        fontDefault: 14,
      },
  },
});

export default styles;
