import { Dispatch } from 'redux';
import { IUser } from '@bridebook/models/source/models/Users.types';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import ValidationError from '@bridebook/toolbox/src/validation/validation-error';
import { AuthActionTypes } from 'lib/auth/action-types';
import { emailExists, emailExistsWithDifferentProvider } from 'lib/auth/actions';
import { getCustomAuthMessages } from 'lib/auth/custom-auth-messages';
import { getFirebaseMessages } from 'lib/auth/firebase-messages';
import {
  AuthBridebookError,
  AuthProviders,
  CustomAuthErrorCodes,
  FirebaseErrorCodes,
} from 'lib/auth/types';
import mapFBErrorToValidationError from 'lib/auth/utils/map-fb-error-to-validation-error';
import { BridebookError, ICredentialsFields, IDeps, IIntlMessageDescriptor } from 'lib/types';

const codesToResetPopup = [
  'auth/account-exists-with-different-credential',
  'auth/email-already-in-use',
];

/**
 * After sign in or sign up, throw appropriate error depending on error code
 * @method throwErrorAfterSignin
 * @param {Object}   error error
 * @param {Function} dispatch fn as passed through dependency middleware
 */
const throwErrorAfterSignin = (error: AuthBridebookError, dispatch: Dispatch<any>): void => {
  switch (error.code) {
    case 'auth/account-exists-with-different-credential':
      dispatch(emailExistsWithDifferentProvider(error.provider));
      break;
    case 'auth/email-already-in-use':
      dispatch(emailExists());
      break;
    default:
      break;
  }
};

interface IAuthAnalyticsExtraProps {
  geolocationCountryCode?: CountryCodes | 'XX';
}

export const registrationSuccessAnalytics = (
  user: IUser,
  pathName: string,
  locale: string,
  extraProps?: IAuthAnalyticsExtraProps,
) => ({
  type: AuthActionTypes.REGISTRATION_SUCCESS_ANALYTICS,
  payload: { user, pathName, locale, extraProps },
});

export const loginSuccessAnalytics = (user: IUser, pathName: string) => ({
  type: AuthActionTypes.LOGIN_SUCCESS_ANALYTICS,
  payload: { user, pathName },
});

export const signUp = (fields: ICredentialsFields) => ({
  type: AuthActionTypes.SIGN_UP,
  payload: { fields },
});

export const disableAuthForm = () => ({
  type: AuthActionTypes.DISABLE_AUTH_FORM,
});

export const signInError = (error: BridebookError, providerName?: AuthProviders) => {
  const oneOfFirebaseErrors: IIntlMessageDescriptor =
    getFirebaseMessages()[error.code as FirebaseErrorCodes];
  const oneOfCustomErrors: IIntlMessageDescriptor =
    getCustomAuthMessages()[error.code as CustomAuthErrorCodes];
  if (oneOfFirebaseErrors) {
    error = mapFBErrorToValidationError(error.code as FirebaseErrorCodes);
  }
  if (oneOfCustomErrors) {
    error = new ValidationError(
      oneOfCustomErrors.defaultMessage,
      error.prop,
      error.code,
      oneOfCustomErrors.defaultMessage,
    );
  }
  SentryMinimal().captureException(error, {
    tags: {
      source: 'Signup signin',
      feature: 'signInError',
    },
    extra: {
      providerName,
    },
  });

  return {
    type: AuthActionTypes.SIGN_IN_ERROR,
    payload: { error, providerName },
  };
};

export const signInOrSignUpError = (error: BridebookError, providerName?: AuthProviders) => {
  const oneOfFirebaseErrors: IIntlMessageDescriptor =
    getFirebaseMessages()[error.code as FirebaseErrorCodes];
  const oneOfCustomErrors: IIntlMessageDescriptor =
    getCustomAuthMessages()[error.code as CustomAuthErrorCodes];
  if (oneOfFirebaseErrors) {
    error = mapFBErrorToValidationError(error.code as FirebaseErrorCodes);
  }
  if (oneOfCustomErrors) {
    error = new ValidationError(
      oneOfCustomErrors.defaultMessage,
      error.prop,
      error.code,
      oneOfCustomErrors.defaultMessage,
    );
  }
  SentryMinimal().captureException(error, {
    tags: {
      source: 'Signup signin',
      feature: 'signInOrSignUpError',
    },
    extra: {
      providerName,
    },
  });

  return {
    type: AuthActionTypes.SIGN_IN_OR_SIGN_UP_ERROR,
    payload: { error, providerName },
  };
};

export const signUpError =
  (error: AuthBridebookError, fields: Pick<ICredentialsFields, 'email'>) =>
  ({ dispatch }: IDeps) => {
    const ssoError = error.code === 'auth/account-exists-with-different-credential';

    const oneOfCustomErrors: IIntlMessageDescriptor =
      getCustomAuthMessages()[error.code as CustomAuthErrorCodes];

    if (oneOfCustomErrors) {
      error = new ValidationError(
        oneOfCustomErrors.defaultMessage,
        error.prop,
        error.code,
        oneOfCustomErrors.defaultMessage,
      ) as AuthBridebookError;
    }

    SentryMinimal().captureException(error, {
      tags: {
        source: 'Signup signin',
        feature: 'signUpError',
      },
    });

    if (ssoError) {
      dispatch({
        type: 'TOGGLE_SSO_MODAL_ANALYTICS',
        payload: {
          toggledModalMethod: 'fromRegistrationError',
          authMethod: AuthProviders.PASSWORD,
          matchedProvider: error.provider,
          enteredEmail: fields.email,
        },
      });
    } else {
      dispatch({
        type: AuthActionTypes.REGISTRATION_ERROR_ANALYTICS,
        payload: { fields, provider: AuthProviders.PASSWORD, error },
      });
    }

    if (codesToResetPopup.indexOf(error.code) > -1) {
      throwErrorAfterSignin(error, dispatch);
    } else if (getFirebaseMessages()[error.code]) {
      error = mapFBErrorToValidationError(error.code as FirebaseErrorCodes) as AuthBridebookError;
    }
    return {
      type: AuthActionTypes.SIGN_UP_ERROR,
      payload: { error },
    };
  };

export const findProviderSuccess =
  (providers: Array<AuthProviders>, error: AuthBridebookError, providerName: AuthProviders) =>
  ({ dispatch }: IDeps) => {
    let errorWithProvider = { ...error };

    if (providers && providers[0]) {
      errorWithProvider = { ...error, provider: providers[0] };
    }

    dispatch({
      type: 'TOGGLE_SSO_MODAL_ANALYTICS',
      payload: {
        toggledModalMethod: 'fromLoginError',
        authMethod: providerName,
        matchedProvider: errorWithProvider.provider,
        enteredEmail: errorWithProvider.email,
      },
    });

    throwErrorAfterSignin(errorWithProvider, dispatch);
    return {
      type: 'FIND_PROVIDER_SUCCESS',
    };
  };

export const toggleSelectedCountryError =
  (error: boolean = false, providerId?: AuthProviders) =>
  ({ dispatch, getState }: IDeps) => {
    const {
      form: { fields },
    } = getState().auth;

    if (error) {
      dispatch({
        type: AuthActionTypes.REGISTRATION_ERROR_ANALYTICS,
        payload: {
          fields,
          provider: providerId,
          error: { message: 'No country selected' },
        },
      });
    }

    return {
      type: AuthActionTypes.TOGGLE_SELECTED_COUNTRY_ERROR,
      payload: error,
    };
  };

export const viewedSignUpPageAnalytics = ({
  selectedWeddingCountry,
}: {
  selectedWeddingCountry?: string | null;
}) => ({
  type: AuthActionTypes.VIEWED_SIGNUP_PAGE_ANALYTICS,
  payload: { selectedWeddingCountry },
});
