import React, { memo } from 'react';
import { Box, Button, FelaCSS } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import { IconCross } from '@bridebook/ui/src/icons/dynamic';
import { IModalProps } from 'app-shared/components/modal';
import Modal from 'components/bbcommon/modal';
import componentStyles from './popup-rounded.style';

interface IProps
  extends Pick<IModalProps, 'show' | 'id' | 'onClose' | 'maxWidth' | 'fullScreenModal'> {
  children: React.ReactNode;
  modalStyle?: FelaCSS;
  buttonStyle?: FelaCSS;
}

const PopupRounded = ({
  show,
  onClose,
  id,
  children,
  modalStyle,
  maxWidth,
  buttonStyle = {},
  fullScreenModal,
}: IProps) => {
  const styles = componentStyles();

  return (
    <Modal {...{ show, onClose, id, maxWidth, fullScreenModal }}>
      <Box style={mergeStyles([styles.contentWrap, modalStyle || {}])}>
        {onClose && (
          <Box style={mergeStyles([styles.buttonWrap, buttonStyle])}>
            <Button theme="ghost" color="space" icon={<IconCross width={18} />} onClick={onClose} />
          </Box>
        )}
        {children}
      </Box>
    </Modal>
  );
};

export default memo(PopupRounded);
