import { alpha } from '../../../fela-utils/color-mixer';
import { colors } from '../../../themes/variables';
import { FelaCSSWithCustomSelectors } from '../../fela/flowtypes';
import { TButtonSelectorSize } from './button-selector';

interface IStyles {
  wrapper: FelaCSSWithCustomSelectors;
}

interface IProps {
  selected: boolean;
  size: TButtonSelectorSize;
  disableActiveState: boolean;
  disabled?: boolean;
}

const styles = ({ selected, size, disableActiveState, disabled }: IProps): IStyles => ({
  wrapper: {
    fontDefault: 26,
    textSizeAdjust: '90%',
    lineHeight: '35px',
    paddingVertical: 5,
    borderRadius: 24,
    textAlign: 'center',
    width: '100%',
    background: colors.white,
    border: `2px solid ${alpha(colors.space, 15)}`,
    cursor: 'pointer',
    ...(!disableActiveState &&
      !disabled && {
        ':active': {
          border: `2px solid ${colors.indigoCrush}`,
        },
      }),
    ...(selected && {
      border: `2px solid ${colors.indigoCrush}`,
    }),
    ...(size === 'small' && {
      fontDefault: 20,
      paddingVertical: 6,
      lineHeight: '27px',
    }),
    ...(size === 'xs' && {
      fontDefault: 16,
      paddingVertical: 2,
      paddingHorizontal: 3,
      lineHeight: '24px',
      width: 'auto',
    }),
    ...(disabled && {
      ...(!selected && {
        border: `2px solid ${colors.space30}`,
        color: colors.space40,
      }),
      background: colors.space04,
      cursor: 'not-allowed',
    }),
    desktopDesign: {
      ':hover': {
        background: colors.space04,
      },
    },
  },
});

export default styles;
