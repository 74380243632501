import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import FooterBottom from 'app-shared/components/footer/footer-bottom/footer-bottom';
import FooterContainer from 'app-shared/components/footer/footer-container/footer-container';
import FooterTop from 'app-shared/components/footer/footer-top/footer-top';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import { getScreenType } from 'lib/app/selectors';
import { ConnectedTypes, IApplicationState } from 'lib/types';


const storeEnhancer = connect((state: IApplicationState) => ({
  isMobile: getScreenType(state).isMobile,
  isCordova: state.app.device.isCordova,
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  hideTop?: boolean;
}

const FooterComponent = ({ isMobile, isCordova, hideTop, dispatch }: IProps) => {
  const market = useMarket();
  const linkAnalytics = (type: string) => (e: SyntheticEvent<HTMLAnchorElement>) => {
    const url = e.currentTarget.href;
    dispatch({
      type: 'USER_CLICKED_OUTBOUND_LINK_ANALYTICS',
      payload: { url, type },
    });
  };

  return (
    <FooterContainer>
      {!hideTop && (
        <FooterTop
          isCordova={isCordova}
          isCollapsible={isMobile}
          market={market}
          onAppBadgeClick={linkAnalytics('app')}
        />
      )}
      <FooterBottom onSocialUrlClick={linkAnalytics('social')} localeSelect={null} />
    </FooterContainer>
  );
};

export default storeEnhancer(FooterComponent);
