import { FunctionComponent, MouseEventHandler } from 'react';
import {
  default as BBUIRadioCheckbox,
  IProps as IBBUIRadioCheckboxProps,
} from '@bridebook/ui/src/components/bbcommon/radio-group/radio';
import { hapticFeedbackLight } from 'lib/mobile-app/utils/haptic-feedback-light';
import { WithFeedback, withFeedback } from '../with-feedback-hoc';

/**
 * This is a wrapper component for web to be able
 * to handle ALL haptic feedback within the app.
 *
 * We could also have moved this to Redux state handling
 * but the issue with that is, that due to latencies
 * involved with Redux, the haptic feedback sometimes
 * doesn't feel "native" enough.
 */
const RadioCheckbox: FunctionComponent<WithFeedback<IBBUIRadioCheckboxProps>> = ({
  withFeedback,
  ...props
}) => {
  const _onClick: MouseEventHandler<HTMLInputElement> = (event) => {
    if (withFeedback) {
      hapticFeedbackLight();
    }

    props.onClick?.(event);
  };

  return <BBUIRadioCheckbox {...props} onClick={_onClick} />;
};

export default withFeedback(RadioCheckbox);
