import React, { ReactNode } from 'react';
import { Box, FelaCSS } from '@bridebook/ui';
import mergeStyles from '@bridebook/ui/src/fela-utils/merge-styles';
import componentStyles from './button-selector.styles';

export type TButtonSelectorSize = 'xs' | 'small' | 'normal';

interface IProps {
  /** Icon displayed on the left */
  startIcon?: ReactNode;
  /** Icon displayed on the right */
  endIcon?: ReactNode;
  /** Button content */
  children: ReactNode;
  /** Button onClick Action */
  onClick?: () => void;
  /** Has it been selected - adds blue border */
  selected?: boolean;
  /** Size of the button */
  size?: TButtonSelectorSize;
  /** Used to prevent flickering when there multiple button choice*/
  disableActiveState?: boolean;
  /** Merges over the existing styles */
  style?: FelaCSS;
  /** For E2E tests */
  dataTest?: string;
  /** Disabled button state */
  disabled?: boolean;
}

export const ButtonSelector = ({
  startIcon,
  children,
  endIcon,
  onClick,
  selected,
  disableActiveState,
  size = 'normal',
  style,
  dataTest,
  disabled,
}: IProps) => {
  const styles = componentStyles({ selected, size, disableActiveState, disabled });
  return (
    <Box
      onClick={!disabled ? onClick : undefined}
      style={mergeStyles([styles.wrapper, style || {}])}
      dataTest={dataTest}>
      {startIcon && startIcon}
      {children}
      {endIcon && endIcon}
    </Box>
  );
};
