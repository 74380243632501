import React from 'react';
import { connect } from 'react-redux';
import { Box } from '@bridebook/ui';
import { MobileSearchTopBar } from 'components/search/mobile-search-controls/mobile-search-top-bar';
import { getScreenType } from 'lib/app/selectors';
import { usePageSettings } from 'lib/page-settings-context';
import { isSearchResultsPage } from 'lib/search/utils';
import { getIsShortlistPage } from 'lib/shortlist/selectors';
import { ConnectedTypes, IApplicationState } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { useDisplayBackButton } from 'lib/utils/hooks/use-display-back-button';
import { isHomePage, usePathWithoutMarket } from 'lib/utils/url';
import { getProfileLoaded } from 'lib/weddings/selectors';
import TopBarBack from './top-bar-back';
import TopBarClosePile from './top-bar-close-pile';
import TopBarHome from './top-bar-home';
import TopBarMenuButtons from './top-bar-menu-buttons';
import TopBarPageTitle from './top-bar-page-title';
import TopBarShortlist from './top-bar-shortlist';
import componentStyles from './top-bar.style';

const storeEnhancer = connect((state: IApplicationState) => ({
  isDesktop: getScreenType(state).isDesktop,
  isMobile: getScreenType(state).isMobile,
  previousPath: state.app.previousPath,
  startedMainNavigationCounter: state.app.startedMainNavigationCounter,
  isShortlistPage: getIsShortlistPage(state),
  pilingCounter: state.app.pilingCounter,
  isHome: isHomePage(state.app.pathname),
  profileLoaded: getProfileLoaded(state),
}));

interface IProps extends ConnectedTypes<typeof storeEnhancer> {
  isSticky?: boolean;
}

const TopBarProtected = ({
  isDesktop,
  isMobile,
  previousPath,
  startedMainNavigationCounter,
  isShortlistPage,
  pilingCounter,
  profileLoaded,
}: IProps) => {
  const { isSupplierPage } = usePageSettings();
  const pathname = usePathWithoutMarket();
  const isSearch = isSearchResultsPage(pathname);
  const isNewSearchResultsHeaderMobile = isSearch && isMobile;
  const styles = componentStyles({
    isLoggedIn: true,
    pathname,
    isNewDesktopHeaderEnabled: true,
    isNewSearchResultsHeaderMobile,
  });
  const isHome = pathname === '/home';
  const isInbox = pathname.startsWith(UrlHelper.inbox.main);
  const showBackButton = useDisplayBackButton(previousPath, pathname, startedMainNavigationCounter);

  const getTopBarIcon = () => {
    if (showBackButton && !isDesktop) return <TopBarBack />;
    if (isSupplierPage) return <TopBarHome />;
    if (
      //The tablet icons on top bar are different for variant and control.
      //On variant we want "fav" icon only on mobile and "home" icon for tablet, and for control we want "fav" icon for mobile and tablet.
      !isShortlistPage &&
      !isHome &&
      isMobile
    )
      return <TopBarShortlist />;
    return <TopBarHome />;
  };

  const showClosePileButton = pilingCounter !== 0;

  if (!profileLoaded) {
    return (
      <Box data-name="top-bar">
        <Box style={styles.wrapperStyle}></Box>
      </Box>
    );
  }

  if (isInbox && !isDesktop) {
    // Show a simple top bar with just a page title
    return (
      <Box data-name="top-bar">
        <Box style={styles.wrapperStyle}>
          <TopBarPageTitle />
        </Box>
      </Box>
    );
  }

  // Even if the component is called again with the correct style, without
  // adding this key the background of the style in the DOM would still appear
  // as transparent: LIVE-14053
  // @ts-ignore FIXME
  const backgroundKey = styles.wrapperStyle.maxWidthTabletSm.backgroundColor;
  return (
    <Box data-name="top-bar">
      <Box style={styles.wrapperStyle} key={backgroundKey}>
        {!isNewSearchResultsHeaderMobile && (
          <>
            <Box style={styles.middleWrapper}>
              {!isDesktop && !showClosePileButton && !isSupplierPage && <TopBarPageTitle />}
            </Box>
            {(!isHome || isDesktop) && (
              <Box style={styles.homeWrapper} data-name="bb-logo-home">
                <Box>
                  <>{showBackButton && !isDesktop ? <TopBarBack /> : getTopBarIcon()}</>
                </Box>
              </Box>
            )}
            {!isHome && !isDesktop && showClosePileButton ? (
              <TopBarClosePile />
            ) : (
              <TopBarMenuButtons />
            )}
          </>
        )}
        {isNewSearchResultsHeaderMobile && <MobileSearchTopBar />}
      </Box>
    </Box>
  );
};

export default storeEnhancer(TopBarProtected);
