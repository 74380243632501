import { createSelector } from 'reselect';
import { getScreenType } from 'lib/app/selectors';
import { IApplicationState } from 'lib/types';

const hasBrowserShare = () => process.browser && navigator && navigator.share;

export const getIsCordova = (state: IApplicationState) => state.app.device.isCordova;
export const getIsIOS = (state: IApplicationState) => state.app.device.isiOS;
export const getIsMacOS = (state: IApplicationState) => state.app.device.isMac;
export const getPathname = (state: IApplicationState) => state.app.pathname;
const getMobileAppLoaded = (state: IApplicationState) => state.mobileapp.loaded;
export const getIsAppKeyboardOpen = (state: IApplicationState) => state.mobileapp.appKeyboardOpened;

/* ############################################################################
 *  MEMOIZED SELECTORS
 * ######################################################################### */

export const getHasNativeShare = createSelector(
  [getIsCordova, hasBrowserShare],
  (isCordova, hasBrowserShare): boolean => isCordova || !!hasBrowserShare,
);

export const hasNativeShareDevice = createSelector(
  [getHasNativeShare, getScreenType],
  (hasNativeShare, { isDesktop }): boolean => hasNativeShare && !isDesktop,
);

export const selectMobileAppLoaded = createSelector(
  [getMobileAppLoaded],
  (isLoaded): boolean => isLoaded,
);
