import React, { useCallback } from 'react';
import Modal from 'components/bbcommon/modal';
import { useToggleFilters } from 'components/search/hooks';
import { getScreenType } from 'lib/app/selectors';
import { IUrl } from 'lib/types';
import { useSelector } from 'lib/utils';
import FilterMobileForm from './filter-mobile-form';

interface IProps {
  show: boolean;
  location: IUrl;
}

const FilterMobile = ({ show, location }: IProps) => {
  const { isMobile, isTablet } = useSelector(getScreenType);
  const toggleFilters = useToggleFilters();
  const close = useCallback(() => {
    toggleFilters();
  }, [toggleFilters]);

  const fullContentHeight = isMobile || isTablet;
  const maxWidth = fullContentHeight ? '100%' : undefined;

  return (
    <Modal
      show={show}
      onClose={close}
      id="search-filters-mobile-modal"
      scrollableContent
      maxWidth={maxWidth}
      fullContentHeight={fullContentHeight}>
      <FilterMobileForm location={location} close={close} />
    </Modal>
  );
};

export default FilterMobile;
