import { useTranslation } from 'next-i18next';
import Router, { useRouter } from 'next/router';
import React, { useEffect, useMemo } from 'react';
import { useFela } from 'react-fela';
import { Box, Tabs } from '@bridebook/ui';
import {
  IconFavourites,
  IconHomeMain,
  IconMessages,
  IconPlanning,
  IconSearchHeart,
} from '@bridebook/ui/src/icons/dynamic';
import { useMarket } from 'app-shared/lib/i18n/hooks/use-market';
import BottomTabMobile, { BottomTab } from 'components/mobile-app/bottom-tab-mobile';
import { getScreenType } from 'lib/app/selectors';
import { scrollToTop } from 'lib/bbcommon/utils/animations';
import { getCheckDirectoryLoaded } from 'lib/i18n/selectors';
import { navigateToVenuesAndSuppliers } from 'lib/search-landing/actions';
import { getIsMapView } from 'lib/search/selectors';
import { showMobileNav, usedHeaderHomeButton, usedHeaderNavigation } from 'lib/ui/actions';
import { ProtectedUrls, getIsMobileNavShown } from 'lib/url-helper';
import { useDispatch, useSelector } from 'lib/utils';
import { getInboxUnreadCount } from 'lib/weddings/selectors';
import componentStyles from './mobile-navigation.style';

const MobileNavigation = () => {
  const { css } = useFela();
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const mobileNavShown = useSelector((state) => state.ui.mobileNavShown);
  const appKeyboardOpened = useSelector((state) => state.mobileapp.appKeyboardOpened);
  const checkDirectoryLoaded = useSelector(getCheckDirectoryLoaded);
  const inboxUnreadCount = useSelector(getInboxUnreadCount);
  const { isMobile } = useSelector(getScreenType);
  const isMapView = useSelector(getIsMapView);

  const router = useRouter();
  const market = useMarket();
  // App nav only visible for mobile logged-in users and non-excluded routes
  const showNav =
    isMobile && checkDirectoryLoaded && !isMapView
      ? getIsMobileNavShown(router.asPath, market.prefix)
      : false;

  useEffect(() => {
    if (showNav !== mobileNavShown) {
      dispatch(showMobileNav(showNav));
    }
  }, [dispatch, mobileNavShown, showNav]);

  const menuList = useMemo(() => {
    if (!mobileNavShown || !checkDirectoryLoaded) return [];

    const navigate: BottomTab['onClick'] = (_, bottomTab) => {
      if (!bottomTab.url) throw new Error('Url not provided to bottom tab option');
      dispatch(usedHeaderNavigation(bottomTab.destination));
      if (bottomTab.id === 'home') {
        dispatch(usedHeaderHomeButton('mobileHomeTab'));
      }
      Router.push(bottomTab.url).then(() => {
        scrollToTop();
      });
    };
    const menu: Record<'home' | 'suppliers' | 'planning' | 'messages' | 'favourites', BottomTab> = {
      home: {
        id: 'home',
        destination: 'home',
        url: ProtectedUrls.home,
        title: t('menu.home'),
        icon: <IconHomeMain width={21} color={'space60'} />,
        iconActive: <IconHomeMain width={21} color={'indigoCrush'} />,
        onClick: navigate,
      },
      suppliers: {
        id: 'search-landing',
        title: t('menu.explore'),
        destination: 'suppliers',
        icon: <IconSearchHeart width={21} color={'space60'} />,
        iconActive: <IconSearchHeart width={21} color={'indigoCrush'} />,
        onClick: () => {
          dispatch(usedHeaderNavigation('suppliers'));
          dispatch(navigateToVenuesAndSuppliers());
        },
      },
      favourites: {
        id: 'shortlist',
        destination: 'shortlist',
        url: ProtectedUrls.shortlist.main,
        title: t('menu.shortlist'),
        icon: <IconFavourites width={21} color="space60" />,
        iconActive: <IconFavourites width={21} color="indigoCrush" />,
        onClick: navigate,
      },
      messages: {
        id: 'messages',
        destination: 'messages',
        url: ProtectedUrls.inbox.main,
        title: t('menu.messages'),
        icon: (
          <Box
            data-name="app-bar-messages-icon"
            style={componentStyles.messageIconWrap(inboxUnreadCount)}>
            <IconMessages color={'space60'} width={21} />
          </Box>
        ),
        iconActive: (
          <Box
            data-name="app-bar-messages-icon"
            style={componentStyles.messageIconWrap(inboxUnreadCount)}>
            <IconMessages color={'indigoCrush'} width={21} />
          </Box>
        ),
        onClick: navigate,
      },
      planning: {
        id: 'planning',
        destination: 'planning',
        url: ProtectedUrls.planning,
        title: t('menu.planning'),
        icon: <IconPlanning width={21} color={'space60'} />,
        iconActive: <IconPlanning width={21} color={'indigoCrush'} />,
        onClick: navigate,
      },
    };
    return [menu.home, menu.suppliers, menu.favourites, menu.messages, menu.planning];
  }, [checkDirectoryLoaded, dispatch, inboxUnreadCount, mobileNavShown, t]);

  if (
    !mobileNavShown ||
    appKeyboardOpened ||
    !checkDirectoryLoaded ||
    !menuList ||
    menuList.length == 0
  ) {
    return null;
  }

  return (
    <div data-name="app-navigation" className={css(componentStyles.appNavWrapper)}>
      <Tabs>
        {menuList.map((menuTab) => (
          <BottomTabMobile key={menuTab.id} bottomTab={menuTab} />
        ))}
      </Tabs>
    </div>
  );
};

export default React.memo(MobileNavigation);
